import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from 'components/layout'
import MetaTags from 'components/MetaTags'
import Header from 'components/Header'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import styled from 'styled-components'

import { media } from 'styles/media'
import { greyLight } from 'styles/colors'
import * as spacing from 'styles/spacing'

import Heading from 'components/Heading'
import List from 'components/List'
import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import GridRow from 'components/grid/GridRow'
import GridItem from 'components/grid/GridItem'
import BookButton from 'components/button/BookButton'
import Button from 'components/button/Button'

const Container = styled.div`
  position: static;
`

const Main = styled.main`
  position: static;
  max-width: 700px;

  small {
    display: block;
    color: ${greyLight};
  }

  ${media.medium`
    padding-right: ${spacing.xLarge};
    max-width: none;
  `};

  ${media.large`
    padding-right: ${spacing.xxxLarge};
  `};

  ${media.xLarge`
    padding-right: 88px;
  `};
`

const OfferTemplate = ({ data }) => {
  const intl = useIntl()

  const {
    prismic: { offer },
  } = data

  let pageAlternativeLangPath

  if ((offer?._meta.alternateLanguages || []).length > 0) {
    pageAlternativeLangPath = `/offers/${offer._meta.alternateLanguages[0].uid}`
  }

  return (
    <Layout>
      <MetaTags
        title={`${offer?.seo_title} | ${intl.formatMessage({
          id: 'offer',
        })} | Hotel Griffintown`}
        description={offer?.seo_description}
        noindex={offer?.hidden || false}
      />

      <Header
        eyebrow={intl.formatMessage({ id: 'offer' })}
        heading={offer?.title}
        fluid={offer?.featured_pictureSharp?.childImageSharp?.fluid}
        pageAlternativeLangPath={pageAlternativeLangPath}
      />

      <Container>
        <Section>
          <Wrapper size='narrow'>
            <GridRow>
              <GridItem xs='1-1' md='2-3'>
                <Main>
                  <Heading size={4} variant='sans'>
                    {intl.formatMessage({ id: 'suites.overview' })}
                  </Heading>

                  <>{RichText.render(offer?.description)}</>

                  <BookButton
                    url={offer?.booking_link?.url}
                    target={offer?.booking_link?.target}
                  />
                </Main>
              </GridItem>
              <GridItem xs='1-1' md='1-3'>
                <List
                  items={offer?.includes.map(({ include }) => include) || []}
                />
              </GridItem>
            </GridRow>
          </Wrapper>
        </Section>
        <Section flushTop>
          <Wrapper>
            <SectionBlock>
              <div
                css={`
                  text-align: center;
                `}
              >
                <Button to='/offers' theme='secondary'>
                  {intl.formatMessage({ id: 'offer.showAll' })}
                </Button>
              </div>
            </SectionBlock>
          </Wrapper>
        </Section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query offerQuery($language: String!, $uid: String!) {
    prismic {
      offer(lang: $language, uid: $uid) {
        title
        description
        featured_picture
        featured_pictureSharp(crop: "headerWide") {
          childImageSharp {
            fluid(maxWidth: 2560, maxHeight: 590) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        hidden
        seo_title
        seo_description
        booking_link {
          ... on PRISMIC__ExternalLink {
            target
            _linkType
            url
          }
        }
        includes {
          include
        }
        _meta {
          alternateLanguages {
            lang
            uid
          }
        }
      }
    }
  }
`

export default OfferTemplate
